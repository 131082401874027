const setupNewsletter = () => {
  const newsletter = document.querySelector('#newsletter');

  if (!newsletter) return;

  const togglerNewsletter = document.querySelector('#newsletter-toggler');

  togglerNewsletter.addEventListener('click', () => {
    newsletter.setAttribute('aria-hidden', true);
  });
};

setupNewsletter();
